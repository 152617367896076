$header-font: vinyl, sans-serif;
$text-font: Cambria, Georgia, serif;
$link-color: white;
$link-hover: #ccc;
$dark-blue: #005588;
$navy: #00008b;
$green: #b8df1e;
$text: #000;
$heading: $navy;
$salmon: #e3b5a5;
$pale-salmon: #f1dad2;
$teal: #0f7486;
$top-nav: $teal;
$light-teal: rgb(64, 185, 201);
$gradient-background: linear-gradient(90deg, rgba(64, 185, 201, 1) 0%, rgba(90, 187, 148, 1) 33%, rgba(146, 192, 35, 1) 100%);

body {
  font-family: $text-font;
  font-size: 18px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.nav-item,
button,
.btn,
.cta {
  font-family: $header-font;
  font-style: normal;
  letter-spacing: 1px;
}

h1,
h3,
.nav-item,
button,
.btn,
.signup {
  text-transform: uppercase;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  color: $navy;

  &.light {
    color: white;
  }
}

h1,
.h1,
h2,
.h2 {
  font-size: 2.5rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .no-gutters {
    padding: 0 0.5rem !important;
  }
}

@media (min-width: 990px) {
  h1,
  .h1,
  h2,
  .h2 {
    font-size: 3.7rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.8rem;
  }

  .h4 {
    font-size: 1.5rem;
  }
}

.border-light {
  border-color: transparent !important;
}

.bg-gradient {
  background: $gradient-background !important;
}

.portfolio-item {
  margin-bottom: 30px;
}

// buttons
.btn {
  font-size: 1.5rem;
  padding: 0.2rem 1.5rem 0 1.5rem;
  border-radius: 8px;
}

.btn.zoom {
  bottom: 4rem;
  position: relative;
  float: right;
  padding: 0;

  &:hover {
    opacity: 0.5;
  }
}

.btn-primary {
  color: $green;
  background-color: $teal;
  border-color: $teal;

  &:hover {
    color: $teal;
    background-color: $green;
    border-color: $green;
  }
}

.btn-secondary {
  color: $salmon !important;
  background-color: $navy;
  border-color: $navy;

  &:hover {
    color: $navy !important;
    background-color: $salmon;
    border-color: $salmon;
  }
}

// nav

.logo.main {
  img {
    width: 122px;
  }
}

.logo.fortmckay {
  img {
    width: 90px;
  }
}

.top-nav {
  background: $top-nav;
  padding: 1.5rem 0;

  a {
    color: white;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $link-color;
  text-transform: uppercase;
  font-size: 1.2rem;

  &.current {
    text-decoration: underline;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 3rem;
  }

  .navbar-dark .navbar-nav .nav-link {
    font-size: 1.8rem;
  }
  .logo.main {
    img {
      width: 224px;
    }
  }

  .logo.fortmckay {
    img {
      width: 159px;
    }
  }
}

// social buttons
.social-button {
  border: none;
  background: transparent;
}

.top-nav {
  .social-button img:hover {
    filter: invert(61%) sepia(70%) saturate(307%) hue-rotate(139deg) brightness(274%) contrast(80%);
  }
}

.nav-item {
  .social-button img:hover {
    filter: brightness(83%) contrast(95%);
  }
}

// footer
footer {
  .logo img {
    width: 115px;
  }
}

// map modal
.modal-header {
  padding: 0;
  border: 0;

  .close {
    color: #fff;
    font-size: 2.5rem;
    // font-weight: 700;
    line-height: 1;
    // text-shadow: 0 1px 0 #fff;
    padding: 0.25rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    opacity: 1 !important;
    outline: 0 !important;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.modal-content {
  background: transparent;
  border: 0;
}

.modal-body {
  padding: 0;
}

@media (min-width: 576px) {
  .modal-xl {
    width: 90%;
    max-width: 1070px;
  }
}

@media (max-width: 990px) {
  footer {
    .logo {
      text-align: center;

      img {
        width: 150px;
      }
    }

    .h4 {
      font-size: 1.5rem;
    }
  }
}

// form
input {
  border-radius: 5px;
  border: 0;
}

.text-fields {
  input {
    width: 98%;
    padding: 0.15rem 0.85rem;
    margin-bottom: 0.5rem;
  }
}

label.checkbox {
  margin-bottom: 0;
}

div.mce_inline_error {
  background-color: transparent !important;
  color: #931730 !important;
  text-transform: none;
  padding: 0 !important;
  margin: 0 !important;
}

#mce-success-response {
  color: #00008b;
  font-size: 1.5rem;
}

@media (max-width: 990px) {
  .mc-field-group {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

// wrap
.wrap {
  .container {
    padding: 2rem auto;
  }

  &.signup {
    background: $gradient-background;
  }
}

.signup,
.timeline {
  font-family: $header-font;
  font-style: normal;
}

// pages
.page {
  h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  // .container {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}

@media (min-width: 990px) {
  .page {
    h1 {
      margin-top: 6.5rem;
      margin-bottom: 3rem;
    }

    // .container {
    //   padding-left: 0;
    //   padding-right: 0;
    // }
  }
}

// Home

.home {
  background: white no-repeat url('../img/home-bg.png') center 5.2%;
}

.hero {
  overflow: hidden;
  position: relative;
}

.vimeo-wrapper {
  width: 100%;
}

.vimeo-wrapper iframe {
  width: 100%;
}

.vp-center {
  align-items: center !important;
}

.wrap {
  &.update {
    background: $pale-salmon;
    a {
      color: $navy;
    }
    li {
      margin-bottom: 1rem;
    }
  }
  &.faq {
    background: $salmon;
    a {
      color: $navy;
    }
  }
}

.update-date {
  font-size: 2.25rem;
  font-style: italic;
}
.community-update-anchor {
  position: relative;
  top: -50px;
}

// Timeline
.timeline {
  .wrap {
    padding: 3rem 0 2rem 0;

    p {
      font-size: 1.5rem;
    }

    &.odd {
      background: $pale-salmon;
    }

    &.even {
      background: $salmon;
    }

    &.teal {
      background: $teal;
      p,
      h2.year {
        color: white;
      }
    }

    &.banner {
      background: #0c0cbc no-repeat url('../img/timeline-banner-bg.jpg') left top;
      background-size: cover;
      padding: 2rem 0;

      h3,
      p {
        color: white;
        letter-spacing: 1px;
      }

      h3 {
        font-size: 3rem;
        padding-bottom: 2rem;

        span {
          display: block;
          font-size: 2rem;
        }
      }

      p {
        font-style: italic;
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 3.75rem;
      }
    }
  }

  h2.year {
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-align: left;

    &.highlight {
      color: $teal;

      span {
        color: $navy;
      }
    }

    span {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: #000;
      display: block;
      font-style: italic;
    }
  }
}

// @media (min-width: 768px) {
//   .timeline {
//     .wrap {
//       padding: 4.5rem 0;

//       &.banner {
//         padding: 4rem 0;
//         h3 {
//           font-size: 4rem;
//         }

//         h3 span,
//         p {
//           font-size: 3.5rem;
//         }
//       }
//     }
//   }
// }

@media (min-width: 990px) {
  .timeline {
    .wrap {
      padding: 4rem 0;

      &.odd {
        .year {
          text-align: left;
          padding-right: 2rem;
        }
      }

      &.even {
        .year {
          text-align: right;
          padding-left: 2rem;
        }
      }
    }

    &.teal h2.year {
      font-size: 5.5rem;
    }

    h2.year {
      font-size: 5rem;
      line-height: 5.5rem;

      span {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin-bottom: -1rem;
      }
    }

    .wrap.banner {
      background-position: 50% top;

      h3 {
        font-size: 5rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .timeline {
    h2.year {
      font-size: 6.5rem;
      line-height: 7rem;

      span {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: -1rem;
      }
    }
  }
}

// Community
.community {
  h2 {
    color: $teal;

    span {
      display: block;
      color: #000;
      font-style: italic;
      font-size: 2rem;
    }
  }

  h3 {
    margin-top: 3rem;
  }

  .img-wrap {
    margin-bottom: 2rem;
    text-align: center;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .community {
    h2 span {
      font-size: 2.5rem;
    }

    hr {
      display: none;
    }

    .img-wrap {
      margin-bottom: 0;
    }

    .odd {
      .img-wrap {
        text-align: left;
        padding-right: 2rem;
      }
    }

    .even {
      .img-wrap {
        text-align: right;
        padding-left: 2rem;
      }
    }
  }
}

// Media
.page .media {
  h1 {
    margin-bottom: 2.5rem;
  }
}

.media {
  font-family: $header-font;
  background: $salmon;

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
  }

  .downloads a,
  .vcard,
  .vcard a {
    display: block;
    font-size: 2rem;
    color: #000;
  }

  .downloads a {
    line-height: 2rem;
  }

  .vcard {
    line-height: 2.5rem;
  }

  .contact {
    margin-bottom: 3rem;

    p {
      font-family: $text-font;
    }
  }
}

@media (min-width: 990px) {
  .media {
    h2 {
      margin-bottom: 1.5rem;
    }

    .contact {
      margin-bottom: 6rem;
    }
  }
}

// footer signup box

.signup {
  color: white;

  span {
    text-transform: none;
    position: relative;
    top: 2px;
  }

  h2 {
    color: white !important;
    // font-size: 3rem;
  }
}

@media (min-width: 990px) {
  .signup {
    h2 {
      font-size: 3.7rem;
    }
  }
}

.sticky.is-sticky {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
}

// Summit
.summit {
  .facts,
  .quotes,
  .notice {
    font-family: vinyl, sans-serif;
    p {
      letter-spacing: 1px;
    }
  }
  .wrap {
    a {
      color: $navy;
    }
  }
}
.summit-logo {
  padding-bottom: 3rem;
}

@media (min-width: 992px) {
  .summit-logo {
    padding-right: 4rem;
  }
}
@media (max-width: 991px) {
  .summit-logo {
    max-width: 360px;
  }
}

.wrap {
  &.facts {
    background: no-repeat url('../img/summit-facts-bg.png') center top;
    background-size: cover;
    padding: 2rem 0;
    color: #fff;
    p {
      font-size: 2rem;
      line-height: 2.5rem;
      padding-bottom: 2rem;
    }
    h2 {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &.quotes {
    background: no-repeat url('../img/quotes-bg.jpg') center bottom;
    background-size: cover;
    color: $dark-blue;
    z-index: -1;

    .container {
      margin-top: 7rem;
      margin-bottom: 30rem;
    }

    .blockquote {
      padding: 2rem 0;
      p {
        position: relative;
        z-index: 10;
        font-style: italic;
        color: $dark-blue;
        font-size: 2rem;
      }
      p:before,
      p:after {
        font-weight: bold;
        font-size: 26rem;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        z-index: -1;
        font-family: 'HVD Bodedo';
        font-weight: normal;
        font-style: normal;
      }
      p:before {
        content: open-quote;
        line-height: 0.45;
        left: -48px;
      }
      p:after {
        content: close-quote;
        line-height: 0.65em;
        margin-left: -0.1em;
      }
    }
    .blockquote-footer {
      padding-top: 3.5rem;
      padding-bottom: 2rem;
      font-size: 2rem;
      color: $dark-blue;
    }
  }

  &.notice {
    p {
      font-size: 2rem;
      // line-height: 5.15rem;
      padding: 4rem 0;
      color: $dark-blue;
    }
    a {
      color: rgba(2, 84, 255, 1);
      font-style: italic;
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .wrap.facts {
    .no-gutters {
      padding: 0 !important;
    }
  }
  .wrap.notice p {
    font-size: 2rem;
    padding: 2em 0;
  }
  .wrap.quotes {
    background-color: rgba(211, 226, 233, 1);
    background-position: center bottom;
    background-size: auto;

    .container {
      margin-top: 4rem;
    }
    .blockquote p {
      line-height: 2.25rem;
    }
    .blockquote p:before {
      left: -12px;
    }
    .blockquote p:before,
    .blockquote p:after {
      font-size: 13rem;
    }
  }
}

@media (max-width: 640px) {
  .wrap.notice p {
    font-size: 1.35rem;
    padding: 1em 0;
  }
}

.previous-updates {
  background-color: rgba(255, 255, 255, 0.15);
}
