@font-face {
    font-family: 'HVD Bodedo';
    src: url('HVDBodedo.eot');
    src: url('HVDBodedo.eot?#iefix') format('embedded-opentype'),
        url('HVDBodedo.woff2') format('woff2'),
        url('HVDBodedo.woff') format('woff'),
        url('HVDBodedo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

